/* Menu Button */
.menuBtn {
  position: relative;
}
.menuButtonInside{
  position: absolute !important;
  top: 3.5%;
  right: 4%;
}
.line {
  fill: none;
  stroke: var(--black);
  stroke-width: 6;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  stroke-linecap: round;
  transform: translateX(5px);
}
.line1 {
  stroke-dasharray: 55 207;
}
.line2 {
  stroke-dasharray: 55 50;
}
.line3 {
  stroke-dasharray: 55 207;
}
.opened .line1 {
  stroke-dasharray: 60 207;
  stroke-dashoffset: -140;
  stroke: var(--white);
}
.opened .line2 {
  stroke-dasharray: 1 50;
  stroke-dashoffset: -30;
  stroke: var(--white);
}
.opened .line3 {
  stroke-dasharray: 60 207;
  stroke-dashoffset: -140;
  stroke: var(--white);
}
/* Menu Button End */

/* Main Menu CSS Start */
.nav {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: none;
  z-index: 110;
}
.menuLoaderBars {
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  rotate: 180deg;
}

.menuLoaderBar {
  display: block;
  width: 0;
  height: 25%;
  background-color: var(--black);
}

.menuLoaderBar:last-child {
  display: none;
}

.menuLayer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: var(--black);
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuLogoContainer {
  position: absolute;
  top: 4.2%;
  left: 4%;
}

.menuLogoContainer img {
  width: 2.5vw;
  filter: invert(1);
}

.wrapper {
  width: 70%;
  display: flex;
  background-image: url("/assets/header/menu-bg.png");
  background-size: 50vw;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: space-between;
  margin: auto auto;
}

.contentLeft {
  display: block;
}

.contentHeadingSerial {
  font-size: 1.3vw;
  margin-right: 15px;
  font-weight: 500;
  color: var(--gray1);
  margin-bottom: 16px;
}

.navLinkItem {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.navLink {
  background-image: linear-gradient(to right, var(--primary) 50%, var(--white) 50%);
  background-size: 200% 100%;
  background-position: 100%;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 300ms ease-out;
  font-size: 5vw;
  font-weight: 500;
  line-height: normal;
  display: block;
  font-family: var(--heading);
}

.navLink:hover {
  background-position: 0;
  transform: translateY(5px) !important;
}

.navLink:hover::before {
  width: 100%;
}

.contentRight {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: "ClashDisplay";
}

.contentRightBody {
  width: 15vw;
  display: block;
}

.contentRightBodyTop {
  margin-top: 100px;
}

.contentTitle {
  font-size: 1vw;
  color: var(--gray);
  margin-bottom: 20px;
}

.socialItems {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
}

.socialItem {
  font-size: 1.4vw;
  font-weight: 400;
  color: var(--white1);
  margin-bottom: 10px;
}

.contentRightBottom div {
  margin-bottom: 20px;
}

/* Responsive CSS */
@media screen and (max-width: 1024px) {
  .menuLogoContainer {
    top: 3%;
    left: 5%;
  }
  .menuLogoContainer img {
    width: 6vw;
  }
  .menuButtonInside{
    position: absolute !important;
    top: 2.4%;
    right: 5%;
  }
  .wrapper {
    width: 80%;
    display: block;
    background-size: 80vw;
  }
  .contentHeadingSerial {
    font-size: 2.5vw;
  }
  .navLink {
    font-size: 7vw;
  }
  .contentRight {
    justify-content: flex-start;
  }
  .contentRightBody {
    width: auto;
  }
  .contentTitle {
    font-size: 3vw;
  }
  .socialItems {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 60px;
  }
  .socialItem {
    font-size: 3.5vw;
  }
  .contentRightBodyTop {
    margin-top: 12vw;
  }
  .contentRightBodyTop:nth-child(2) {
    margin-top: 5vw;
  }
  .menuSvg {
    width: 6vw;
    height: auto;
    z-index: 999;
  }
}
@media screen and (max-width: 991px) {
  .menuSvg {
    width: 7vw;
  }
}

@media screen and (max-width: 480px) {
  .menuButtonInside{
    position: absolute !important;
    top: 2%;
    right: 7%;
  }
  .wrapper {
    padding-top: 10vw;
  }
  .menuLogoContainer {
    top: 3.5%;
    left: 8%;
  }
  .menuLogoContainer img {
    width: 35px;
  }
  .navLink {
    font-size: 9vw;
  }
  .contentHeadingSerial {
    font-size: 3vw;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .contentTitle {
    font-size: 4vw;
  }
  .socialItems {
    column-gap: 20px;
  }
  .socialItem {
    font-size: 5vw;
  }
  .menu svg {
    width: 40px;
    height: auto;
    z-index: 999;
  }
}
