.enCta .ctaSpan::after {
    content: "";
    background: var(--black);
    height: 1.5px;
    width: 100%;
    transform: scaleX(1);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 300ms ease-out;
  }
  .enCta:hover .ctaSpan:after {
    transform: scaleX(0);
  }
  .enCta:hover .ctaEmoji {
    animation-name: waveAnimation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
  }
  @keyframes waveAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    10% {
      -webkit-transform: rotate(14deg);
      transform: rotate(14deg);
    }
  
    20% {
      -webkit-transform: rotate(-8deg);
      transform: rotate(-8deg);
    }
  
    30% {
      -webkit-transform: rotate(14deg);
      transform: rotate(14deg);
    }
  
    40% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
  
    50% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg);
    }
  
    60% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }