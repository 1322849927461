.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.5s ease-in-out;
}
.mainLoaderBg {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.mainLoaderBar {
  width: 20%;
  height: 100vh;
  background: var(--black);
}
.preloader p {
  display: flex;
  color: var(--white);
  font-size: 48px;
  align-items: center;
  position: absolute;
  z-index: 1;
  font-family: var(--heading);
}

.preloader p span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 50%;
  margin-right: 10px;
}

.preloader svg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
}

@media screen and (max-width: 991px) {
  .mainLoaderBar {
    width: 25%;
    height: 100vh;
    background: var(--black);
  }
  .mainLoaderBar:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .preloader p {
    font-size: 36px;
  }
}
